/* eslint-disable react/no-array-index-key */
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import { Module } from 'types';

import { Step } from 'components/workflow/Step';
import * as styles from './style.module.scss';

type Props = {
  content: Module['content'],
  height?: string
};

function Content({ content, height = '240px' }: Props) {
  const { t } = useTranslation();

  return (
    <section className="section container-lg mt-5">
      {
        content?.map(({
          img, title: itemTitle, list, texts,
        }, i) => {
          const image = getImage(img);

          return (
            <Step
              key={i}
              isOdd={i % 2 === 1}
              header={<Trans>{itemTitle}</Trans>}
              body={(
                <>
                  {
                    texts?.map((text, j) => (
                      <p key={j}>
                        <Trans>{text}</Trans>
                      </p>
                    ))
                  }
                  {
                    list && (
                      <ul>
                        {
                          list.map((line: string) => (
                            <li key={line}>
                              {t(line)}
                            </li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )}
            >
              {
                image && (
                  <GatsbyImage
                    className={styles.cardImg}
                    image={image}
                    alt={itemTitle || ''}
                    style={{ height }}
                  />
                )
              }
            </Step>
          );
        })
      }
    </section>
  );
}

export {
  Content,
};
