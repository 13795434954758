import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ImageDataLike } from 'gatsby-plugin-image';

import { OtherModule } from './OtherModule';

type Props = {
  prevSlug: string
  prevTitle: string
  prevImg: ImageDataLike
  nextSlug: string
  nextTitle: string
  nextImg: ImageDataLike
};

function OtherModules({
  prevSlug,
  prevTitle,
  prevImg,
  nextTitle,
  nextSlug,
  nextImg,
}: Props) {
  return (
    <Container
      fluid
      className="g-0 mt-5"
    >
      <Row className="g-0">
        <Col
          md="6"
          className="order-lg-1"
        >
          <OtherModule
            title={nextTitle}
            thumb={nextImg}
            slug={nextSlug}
          />
        </Col>
        <Col md="6">
          <OtherModule
            title={prevTitle}
            thumb={prevImg}
            slug={prevSlug}
            isPrev
          />
        </Col>
      </Row>
    </Container>
  );
}

export {
  OtherModules,
};
