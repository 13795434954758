import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { IFluidObject } from 'gatsby-background-image';

import { Module } from 'types';
import {
  Content, DownloadDocument, GoHome, Heading, OtherModules,
} from 'components/details';
import { Layout } from 'components/Layout';
import { Contact } from 'components/contact';

type PartialModule = Pick<Module, 'id' | 'slug' | 'title' | 'headerImg'>;

type Data = {
  module: Module,
  footer: {
    childImageSharp: {
      fluid: IFluidObject
    }
  }
  prevModule: PartialModule,
  nextModule: PartialModule,
};

export default function ModuleDetails({ data }: PageProps<Data>) {
  const { language } = useI18next();
  const {
    title, content, headerImg, documents,
  } = data.module;
  const { title: prevTitle, slug: prevSlug, headerImg: prevHeaderImg } = data.prevModule;
  const { title: nextTitle, slug: nextSlug, headerImg: nextHeaderImg } = data.nextModule;
  const footerImage = data.footer.childImageSharp.fluid;

  const document = documents[language];
  return (
    <Layout footerImage={footerImage}>
      <Heading img={headerImg}>
        <h1 className="text-center">
          <Trans>{title}</Trans>
        </h1>
      </Heading>
      <GoHome />
      <Content
        content={content}
        height="300px"
      />
      <DownloadDocument document={document} />
      <OtherModules
        nextImg={nextHeaderImg}
        nextTitle={nextTitle}
        nextSlug={nextSlug}
        prevImg={prevHeaderImg}
        prevTitle={prevTitle}
        prevSlug={prevSlug}
      />
      <Contact />
    </Layout>
  );
}

export const query = graphql`
    query ProjectDetailsPage($slug: String, $prevSlug: String, $nextSlug: String, $language: String!) {
        module: modulesJson(slug: {eq: $slug}) {
            title
            slug
            id
            documents {
                en {
                    extension
                    publicURL
                    size
                }
                et {
                    extension
                    publicURL
                    size
                }
                ru {
                    extension
                    publicURL
                    size
                }
            }
            content {
                list
                img {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                            quality: 100
                            height: 300
                        )
                    }
                }
                title
                texts
            }
            headerImg {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        prevModule: modulesJson(slug: {eq: $prevSlug}) {
            slug
            id
            title
            headerImg {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        nextModule: modulesJson(slug: {eq: $nextSlug}) {
            slug
            id
            title
            headerImg {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        footer: file(relativePath: { eq: "footer.jpg" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
