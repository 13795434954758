import React from 'react';
import classnames from 'classnames';
import BackgroundImage from 'gatsby-background-image';
import { ImageDataLike } from 'gatsby-plugin-image';
import { Trans, Link } from 'gatsby-plugin-react-i18next';

import * as styles from './style.module.scss';

type Props = {
  isPrev?: boolean;
  slug: string;
  title: string;
  thumb: ImageDataLike;
};

function OtherModule({
  slug, thumb, title, isPrev,
}: Props) {
  const imageData = [
    'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))',
    (thumb as any).childImageSharp.fluid, // eslint-disable-line @typescript-eslint/no-explicit-any
  ];

  return (
    <BackgroundImage
      Tag="div"
      fluid={imageData}
    >
      <Link
        className={classnames(
          'text-white d-flex align-items-center justify-content-center text-center',
          styles.otherModule,
          isPrev ? styles.otherModulePrev : styles.otherModuleNext,
        )}
        to={`/modules/${slug}`}
      >
        <span className={classnames('mx-5', styles.moduleTitle)}>
          <Trans>{title}</Trans>
        </span>
      </Link>
    </BackgroundImage>
  );
}

export {
  OtherModule,
};
