import React from 'react';
import { Link, Trans } from 'gatsby-plugin-react-i18next';

import classnames from 'classnames';
import { Button } from 'react-bootstrap';

function GoHome() {
  return (
    <section className="section container-lg mt-5">
      <Link
        to="/"
        className="d-inline-block text-decoration-none"
      >
        <Button
          variant="outline-primary"
          type="button"
          size="lg"
        >
          <span className={classnames('me-2 d-inline-block')}>
            &larr;
          </span>
          <span><Trans>modules-go-home</Trans></span>
        </Button>
      </Link>
    </section>
  );
}

export {
  GoHome,
};
