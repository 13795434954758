import React from 'react';
import { Button } from 'react-bootstrap';
import { downloadURI, humanFileSize } from 'utils';
import { Trans } from 'gatsby-plugin-react-i18next';
import { Document } from 'types';

type Props = {
  document: Document
};

function DownloadDocument({ document }: Props) {
  const { size, publicURL, extension } = document;
  return (
    <section className="section text-center">
      <Button
        size="lg"
        variant="outline-primary"
        type="button"
        onClick={() => downloadURI(publicURL)}
      >
        <Trans
          values={{
            extension,
            size: humanFileSize(size, true),
          }}
        >
          modules-download-document
        </Trans>
      </Button>
    </section>
  );
}

export {
  DownloadDocument,
};
