import React, { PropsWithChildren } from 'react';

import { ImageDataLike } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import classnames from 'classnames';
import * as styles from './style.module.scss';

type Props = PropsWithChildren<{
  img: ImageDataLike
}>;

function Heading({ children, img }: Props) {
  const imageData = [
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
    (img as any).childImageSharp.fluid, // eslint-disable-line @typescript-eslint/no-explicit-any
  ];

  return (
    <BackgroundImage
      Tag="div"
      fluid={imageData}
    >
      <div className={classnames(styles.heading, 'text-white d-flex justify-content-center align-items-center')}>
        <div>
          {children}
        </div>
      </div>
    </BackgroundImage>
  );
}

export {
  Heading,
};
